<template>
  <div>
    <b-modal
      v-model="modalShow"
      modal-class="filter-dialog"
      ok-only
      ok-title="Ok"
      @ok="sendParams()"
      @hide="$emit('clearAction')"
    >
      <div v-if="fields">
        <b-form-group
          v-for="(item,index) in fields"
          :key="index"
          :label="$t(item.label)"
          class="me-4"
          label-cols-md="4"
          content-cols-lg="8"
        >
          <b-form-input v-if="item.type === 'text'" type="text" v-model="item.value"/>
          <v-select
            v-if="item.type === 'entity' || item.type === 'item' || item.type === 'entityOption' || item.type === 'skill' || item.type === 'customer' || item.type === 'user' || item.type === 'employee' || item.type === 'enum' || item.type === 'importantUpdate'"
            v-model="item.value"
            :options="item.list"
            :multiple="item.multiple ? true : false"
          />
          <custom-select
            v-if="item.type === 'customSelect'"
            :value="item.value"
            :name="item.name"
            :options="item.list"
            :list="item.list"
            :searchable="true"
            :lazyLoad="typeof item.lazyLoad === 'undefined' ? true : item.lazyLoad"
            :multiple="item.multiple ?? false"
            :preload="item.preload ?? false"
            :taggable="item.taggable ?? false"
            :resource="item.resource ?? null"
            :resource-type="item.resourceType ?? null"
            :enum-name="item.enumName ?? null"
            :entity-type="item.entityType ?? null"
            :category-name="item.categoryName ?? null"
            :params="{}"
            @input="customSelectSetValue(item, $event)"
            @customSelectOptionsUpdated="updateElementList"
          />
          <v-select
            v-if="item.type === 'station'"
            v-model="item.value"
            :options="item.list"
            taggable
          />
          <v-select
            v-if="item.type === 'select'"
            v-model="item.value"
            :options="item.list"
            taggable
          />
          <datepicker
            v-if="item.type === 'date'"
            v-model="item.value"
            :enableTimePicker="false"
          />
          <datepicker
            v-if="item.type === 'datetime'"
            v-model="item.value"
            :enableTimePicker="true"
          />
          <datepicker
            v-if="item.type === 'range'"
            v-model="item.value"
            range
            :enableTimePicker="false"
          />
          <datepicker
            v-if="item.type === 'rangeStatistics'"
            v-model="item.value"
            range
            :enableTimePicker="false"
          />
        </b-form-group>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Datepicker from 'vue3-date-time-picker';
import 'vue3-date-time-picker/dist/main.css'
import vSelect from "vue-select";
import ResourceUtils from "@/mixins/ResourceUtils";
import ListUtils from "@/mixins/ListUtils";
import CustomSelect from "@/components/Inputs/CustomSelect.vue";
import TableLiteUtils from "@/mixins/TableLiteUtils";

export default {
  components: {
    vSelect,
    CustomSelect,
    Datepicker
  },
  props: {
    pageName: { type: String, default: null },
    fieldsArray: {
      type: Array, default() {
      }
    },
    action: {type: Boolean, default: false},
  },
  watch: {
    action() {
      this.modalShow = this.action
    }
  },
  mixins: [ResourceUtils, ListUtils, TableLiteUtils],
  data() {
    return {
      modalShow: false,
      fields: this.fieldsArray,
      listsIndexedByType: [],
      fieldSubmits: 0,
      resourcesTypesToIndexing: [
        'entity',
        'station',
        'importantUpdate',
        'enum',
        'user',
        'customer',
        'employee',
        'skill',
        'item',
        'entityOption',
      ]
    }
  },
  created() {
    this.fields.forEach(element => {
      if (element.type !== 'customSelect') {
        const found = this.indexingElement(element) ? this.getCachedElement(element) : null

        if (found) {
          element.list = found.list
        }

        if (typeof element.lazyLoad === 'undefined') {
          element.lazyLoad = false
        }

        if (this.$helper.hasQueryParameter(this.$route, element.name)) {
          element.lazyLoad = false
        }

        if (typeof element.list === 'undefined' && false === element.lazyLoad) {
          this.loadOptions(element, null)
        }
      }
    });

    // need wait for all select inputs are fully ready, all options lists loaded
    if (this.$helper.hasAnyOfQueryParameter(this.$route, this.fields.map((field) => field.name))) {
      setTimeout(() => {
        this.sendParams()
      }, 1000)
    }
  },
  mounted() {
    this.fields.forEach(field => {
      this.setValueFromUrl(field)
    });
  },
  methods: {
    customSelectSetValue(element, value) {
      element.value = value
    },
    updateElementList(fieldName, fieldList) {
      const field = this.getFieldByName(fieldName)
      if (field) {
        field.list = fieldList
        this.setValueFromUrl(field)
      }
    },
    getFieldByName(fieldName) {
      let found = null;
      this.fields.forEach(field => {
        if (field.name === fieldName) {
          found = field
        }
      })

      return found
    },
    getElementQueryName(element) {
      return element.name
    },
    findElementOptionInList(field, value) {
      let found = {}

      if (typeof field.list === 'undefined') {
        return found
      }

      field.list.forEach(option => {
        if (option.id && option.id === value) {
          found = {
            id: option.id,
            label: option.label
          }
        }
      })

      return found
    },
    setValueFromUrl(field) {
      if (field.type === 'customSelect') {
        return
      }

      if(false === this.$helper.hasQueryParameter(this.$route, field.name)) {
        return;
      }

      const query = JSON.parse(JSON.stringify(this.$route.query));
      let value = decodeURIComponent(query[this.getElementQueryName(field)])

      let selectedValue = null;

      if (field.type === 'select') {
        if (field.multiple) {
          if (value.includes(',')) {
            value = value.split(',')
          }
          if (false === Array.isArray(value)) {
            value = [value]
          }
        }

        if (Array.isArray(value)) {
          selectedValue = []
          value.forEach(val => {
            selectedValue.push(this.$helper.findElementOptionInList(field.list, val))
          })
        } else {
          selectedValue = this.$helper.findElementOptionInList(field.list, value)
        }
      } else {
        selectedValue = value
      }

      field.value = selectedValue
    },
    loadOptions(element, search) {
      if (element.type === 'entity' || element.type === 'station') {
        element.list = []
        this.getResourceList(this[element.entityName], element.list, {search, pagination: false})
      }
      if (element.type === 'importantUpdate') {
        element.list = []
        this.getResourceList(this.$Documents, element.list, {search, importantUpdate: true, pagination: false})
      }
      if (element.type === 'enum') {
        element.list = []
        this.getEnumList(element.enumName, element.list)
      }
      if (element.type === 'user') {
        element.list = []
        this.getUserList({params: {search, pagination:false}}, element.list);
      }
      if (element.type === 'customer') {
        element.list = []
        this.getUserList({params: {search, role: "ROLE_CUSTOMER", pagination: false}}, element.list);
      }
      if (element.type === 'employee') {
        element.list = []
        this.getUserList({params: {search, role: "ROLE_EMPLOYEE", pagination: false}}, element.list);
      }
      if (element.type === 'skill') {
        element.list = []
        this.getSkillList({params: {search, pagination: false}}, element.list);
      }
      if (element.type === 'item') {
        element.list = []
        if (element.categoryName) {
          this.getItemList({params: {search, itemCategory: element.entityType, 'category.name': element.categoryName, pagination: false}}, element.list);
        } else {
          this.getItemList({params: {search, itemCategory: element.entityType, pagination: false}}, element.list);
        }
      }
      if (element.type === 'entityOption') {
        element.list = []
        this.getResourceList(this.$EntityOptions, element.list, {search, type: element.entityType, pagination: false});
      }
      this.setCachedElement(element)
    },
    searchOptions(element, search) {
      this.loadOptions(element, search)
    },
    indexingElement(element) {
      return this.resourcesTypesToIndexing.includes(element.type)
    },
    setCachedElement(element) {
      const cache = {
        type: element.type,
        list: element.list,
      }

      if (element.type === 'entity' || element.type === 'station') {
        cache.entityName = element.entityName
      }

      if (element.type === 'entityOption') {
        cache.entityType = element.entityType
      }

      if (element.type === 'item' && element.categoryName) {
        cache.entityType = element.entityType
        cache.categoryName = element.categoryName
      }

      if (element.type === 'item' && !element.categoryName) {
        cache.entityType = element.entityType
      }

      if (element.type === 'enum') {
        cache.enumName = element.enumName
      }

      this.listsIndexedByType.push(cache)
    },
    getCachedElement(element) {
      let foundElement = null;

      this.listsIndexedByType.filter(function(cachedElement) { return cachedElement.type === this.element.type }, { element })
        .forEach((cachedElement) => {
          if ((element.type === 'entity' || element.type === 'station') && element.entityName === cachedElement.entityName) {
            foundElement = cachedElement
          }

          if (element.type === 'entityOption' && element.entityType === cachedElement.entityType) {
            foundElement = cachedElement
          }

          if (element.type === 'item' && element.entityType === cachedElement.entityType && element.categoryName && cachedElement.categoryName && element.categoryName === cachedElement.categoryName) {
            foundElement = cachedElement
          }

          if (element.type === 'item' && element.entityType === cachedElement.entityType && !element.categoryName && !cachedElement.categoryName) {
            foundElement = cachedElement
          }

          if (element.type === 'enum' && element.enumName === cachedElement.enumName) {
            foundElement = cachedElement
          }

          if (element.type !== 'enum' && element.type !== 'entity' && element.type !== 'station' && element.type !== 'item' && element.type !== 'entityOption'){
            foundElement = cachedElement
          }
        })

      return foundElement;
    },
    sendParams() {
      const params = []
      const paramsQuery = {}
      const originalQuery = JSON.parse(JSON.stringify(this.$route.query))

      this.fields.forEach(element => {
        let value = element.value
        if (element.type === 'range' && value) {
          params.push(
            {
              name: element.name + '[after]',
              value: value[0].getFullYear() + '-' + (value[0].getMonth() + 1) + '-' + value[0].getDate() + 'T00:00:00'
            }
          )
          params.push(
            {
              name: element.name + '[before]',
              value: value[1].getFullYear() + '-' + (value[1].getMonth() + 1) + '-' + value[1].getDate() + 'T23:59:59'
            }
          )

          return
        }
        if (element.type === 'rangeStatistics' && value) {
          params.push(
            {
              name: 'fromDate[after]',
              value: value[0].getFullYear() + '-' + (value[0].getMonth() + 1) + '-' + value[0].getDate() + 'T00:00:00'
            }
          )
          params.push(
            {
              name: 'toDate[before]',
              value: value[1].getFullYear() + '-' + (value[1].getMonth() + 1) + '-' + value[1].getDate() + 'T23:59:59'
            }
          )

          return
        }
        if ((element.type === 'entity' || element.type === 'item' || element.type === 'user' || element.type === 'employee' || element.type === 'customer') && value) {
          value = element.value.numberId
          if(element.multiple) {
            value = []
            element.value.forEach(el => {
              value.push(el.numberId)
            });
          }
        }
        if (element.type === 'importantUpdate' && value) {
          value = element.value.numberId
        }
        if (element.type === 'enum' && value) {
          value = element.value.id
          if(element.multiple) {
            value = []
            element.value.forEach(el => {
              value.push(el.id)
            });
          }
        }
        if (element.type === 'entityOption' && value) {
          value = element.value.id
          if(element.multiple) {
            value = []
            element.value.forEach(el => {
              value.push(el.id)
            });
          }
        }
        if (element.type === 'customSelect' && value) {
          value = element.value.id
          if(element.multiple) {
            value = []
            element.value.forEach(el => {
              if (el) {
                value.push(el.id)
              }
            });
          }
        }
        if (element.type === 'station' && value) {
          value = element.value.label
        }
        if (element.type === 'select' && value) {
          value = element.value.id
        }
        if (element.type === 'skill' && value) {
          value = element.value.label
        }
        params.push(
          {
            name: element.name,
            value: value,
          }
        )

        if (null !== value && typeof value !== 'undefined') {
          if (Array.isArray(value)) {
            paramsQuery[element.name] = []
            value.forEach(val => {
              paramsQuery[element.name].push(encodeURIComponent(val));
            })
          } else {
            paramsQuery[element.name] = encodeURIComponent(value);
          }
        } else {
          // delete element from original query if element has empty value after submit changes
          delete originalQuery[element.name]
        }
      });

      const query = Object.assign(originalQuery, paramsQuery);
      this.$router.push({ query: query });

      this.$emit("clearAction")
      this.$emit("paramsChange", params)
    }
  }
}
</script>

<style lang="scss">
@import "vue-select/src/scss/vue-select.scss";
</style>
