export default {
  methods: {
    setTableLiteSettings (page, settings) {
      const tableLiteSettings = this.getTableLiteSettings()
      const otherSettings = tableLiteSettings.filter(settings => settings.page !== page)

      let currentSettings = tableLiteSettings.filter(settings => settings.page === page)
      currentSettings = currentSettings ? currentSettings.shift() : {}

      const pageSettings = {
        currentPage: typeof currentSettings !== 'undefined' && currentSettings.currentPage ? currentSettings.currentPage : 1,
        perPage: typeof currentSettings !== 'undefined' && currentSettings.perPage ? currentSettings.perPage : null,
        columns: typeof currentSettings !== 'undefined' && currentSettings.columns ? currentSettings.columns : [],
        params: typeof currentSettings !== 'undefined' && currentSettings.params ? currentSettings.params : {},
        advancedParams: typeof currentSettings !== 'undefined' && currentSettings.advancedParams ? currentSettings.advancedParams : {},
      }

      if (settings.perPage) {
        pageSettings.perPage = settings.perPage
      }

      if (settings.columns) {
        pageSettings.columns = []
        settings.columns.forEach((column) => {
          pageSettings.columns.push(column.field)
        })
      }

      if (settings.params) {
        Object.keys(settings.params).forEach(key => {
          if (null !== settings.params[key] && '' !== settings.params[key]) {
            pageSettings.params[key] = settings.params[key]
          }
        });
      }

      if (settings.advancedParams) {
        settings.advancedParams.forEach((element) => {
          if (element.value !== '' && element.value !== null) {
            pageSettings.advancedParams[element.name] = element
          }
        })
      }

      localStorage.setItem('tableLiteSettings2', JSON.stringify(otherSettings.concat([{
        page: page,
        currentPage: pageSettings.currentPage,
        perPage: pageSettings.perPage,
        columns: pageSettings.columns,
        params: pageSettings.params,
        advancedParams: pageSettings.advancedParams,
      }])))
    },
    getTableLiteSettings () {
      let value = localStorage.getItem('tableLiteSettings2')

      if (value === null || value === '') {
        value = JSON.stringify([])
      }

      return JSON.parse(value)
    },
    getTableLiteSettingsForPage (page) {
      const tableLiteSettings = this.getTableLiteSettings()
      const found = tableLiteSettings.filter(settings => settings.page === page)
      return found ? found.shift() : {}
    },
    getTableLiteSettingsForPageAndKey (page, key) {
      const tableLiteSettings = this.getTableLiteSettingsForPage(page)
      let value = tableLiteSettings && tableLiteSettings[key] ? tableLiteSettings[key] : null;

      if (value === null && key === 'perPage') {
        value = this.$store.state.defaultPerPage
      }

      if (value === null && key === 'currentPage') {
        value = 1
      }

      if (value === null && key === 'params') {
        value = {}
      }

      if (value === null && ( key === 'columns' || key === 'advancedParams')) {
        value = []
      }

      return value
    }
  },
}
